.sideBar {
    margin-right: 50px;
    padding-top: 10%;
    margin-left: 100px;
    height: 334px;
    font-family: 'Architects Daughter', cursive;
    text-transform: capitalize;
    animation: fadeIn 3200ms both
}

ul {
    position: relative;
    list-style-type: none;
}

.menu_option>span {
    font-size: 12px;
    display: none;
    margin-left: -200px;
    color: rgba(156, 155, 155, 0.541);
    letter-spacing: 2px;
}

.menu_option>i {
    margin-right: 6px;
    font-size: 15px;
    text-align: left;
}

.menu_option:hover span {
    display: block;
}

.menu_option {
    background: #0A192F;
    color: #6EF7EC;
    text-align: center;
    height: 2.7em;
    width: 4em;
    line-height: 2.5em;
    border-bottom: 1px solid gray;
    position: relative;
    display: block;
    text-decoration: none;
    box-shadow: -2em 1.5em 0 #061224;
}

.menu_option:hover {
    background: #0A192F;
    color: white;
    -webkit-transform: translate(0.9em, -0.9em);
    transform: translate(0.9em, -0.9em);
    -webkit-transition: all .25s linear;
    transition: all .25s linear;
    box-shadow: -2em 2em 0 #061224;
}

.menu_option:hover:before,
.menu_option:hover:after {
    -webkit-transition: all .25s linear;
    transition: all .25s linear;
}

.menu_option:hover:before {
    background: rgb(6, 233, 214);
    width: 1em;
    top: .5em;
    left: -1em;
}

.menu_option:hover:after {
    background: rgb(12, 155, 143);
    width: 1em;
    bottom: -2.5em;
    left: 1em;
    height: 4em;
}

.menu_option:before,
.menu_option:after {
    content: '';
    position: absolute;
    -webkit-transition: all .25s linear;
    transition: all .25s linear;
    width: .5em;
}

.menu_option:after {
    height: 4em;
    background: #00FFEB;
    bottom: -2.25em;
    left: 1.5em;
    -webkit-transform: rotate(90deg) skew(0, 45deg);
    transform: rotate(90deg) skew(0, 45deg);
}

.menu_option:before {
    height: 2.5em;
    background: #071426;
    top: .25em;
    left: -.5em;
    -webkit-transform: skewY(-45deg);
    transform: skewY(-45deg);
}

#menu {
    display: none;
    font-size: 25px;
    color: var(--color2);
    position: absolute;
    top: 10%;
    left: 4%;
}

.sideBar:hover>ul {
    display: block;
}

@media only screen and (max-width: 800px) {
    .sideBar {
        position: absolute;
        left: -10%;
        top: 10%;
        z-index: 100;
    }
    .sideBar ul {
        display: none
    }
    #menu {
        display: block
    }
    .menu_option:hover span {
        display: none;
    }
}