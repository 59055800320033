.App {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    animation: fadeIn 3200ms both;
    height: 100vh;
}

.signature {
    position: absolute;
    top: 5px;
}