.Links {
    position: absolute;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Links i {
    color: #B0B3C2;
    margin: 6px 0;
}

.Links i:hover {
    color: white;
}