 .contact {
     width: 450px;
     padding: 0 1%;
     padding-bottom: 5px;
     animation: fadeIn 2200ms;
 }
 
 .contact h1 {
     font-size: 20px;
     text-align: center;
     color: rgba(255, 255, 255, 0.726);
     margin-bottom: 30px;
     margin-top: 80px;
     letter-spacing: 4px;
     margin-top: 5%;
 }
 
 .contact input,
 textarea {
     width: 400px;
     height: 13px;
     margin-bottom: 10px;
     background: none;
     border: none;
     box-shadow: 1px 1px 4px 1px #172d4e;
     padding: 10px;
     color: var(--color2);
     outline: none;
 }
 
 .contact input:hover,
 textarea:hover {
     box-shadow: 1px 1px 4px 1px var(--color2)
 }
 
 .contact textarea {
     height: 103px;
 }
 
 .contact button {
     width: 100px;
     height: 40px;
     margin-left: 70%;
 }
 
 .contact p {
     text-align: center;
     color: white;
     font-family: 'Architects Daughter', cursive;
 }
 
 @keyframes bounce {
     from,
     20%,
     53%,
     80%,
     to {
         animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
         transform: translate3d(0, 0, 0);
     }
     40%,
     43% {
         animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
         transform: translate3d(0, -30px, 0);
     }
     70% {
         animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
         transform: translate3d(0, -15px, 0);
     }
     90% {
         transform: translate3d(0, -4px, 0);
     }
 }