body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Fredoka One', cursive;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    --color1: #0A192F;
    --color2: #00FFEB;
    background: var(--color1);
    display: grid;
    height: 100vh;
    place-items: center;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: 800px) {
    .sideBar:hover~.Home,
    .sideBar:hover~.projects,
    .sideBar:hover~.skills,
    .sideBar:hover~.contact {
        opacity: 10%;
    }
}