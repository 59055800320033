.skills {
    padding-top: 70px;
    padding-right: 5px;
    padding-bottom: 5px;
    animation: fadeIn 3200ms;
}

.skills button {
    float: right;
    margin-right: 7%;
}

.skills i {
    margin: 0 4px;
}

.sk_list {
    display: flex;
}

.skills h1 {
    font-size: 25px;
    text-align: center;
    color: rgba(255, 255, 255, 0.726);
    margin-bottom: 30px;
    margin-top: 80px;
    letter-spacing: 4px;
}

.skills h6 {
    border-bottom: 1px solid #152B4D;
    height: 20px;
    color: #B2B6BD;
    font-size: 10px;
    letter-spacing: 1px;
    font-family: 'Roboto', sans-serif;
}

.sk_row {
    display: flex;
    margin: 5px 20px;
}

.sk_items {
    display: flex;
    margin: 5px;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
    border-bottom: 1px solid #152B4D;
    border-left: 1px solid #152B4D;
}

.sk-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 58px;
    margin-right: 10px;
    color: rgba(156, 155, 155, 0.541);
    font-family: 'Architects Daughter', cursive;
    letter-spacing: .5px;
}

.sk-item>p {
    opacity: 0%;
    text-transform: capitalize;
}

.sk-item:hover p {
    opacity: 100%;
}

.sk_right {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.sk_right>.sk_row {
    margin: 5px;
}

.sk_right>h6 {
    border: none;
}

.sk_right>sk_row {
    display: flex;
}

.sk_right {
    border-left: 1px solid #152B4D;
    height: 80%;
    margin-top: 1%;
    padding-left: 20px;
}

.sk_left {
    padding-right: 20px;
}

@media only screen and (max-width: 1117px) {
    .sk_list {
        flex-direction: column;
    }
    .sk_right {
        flex-direction: row;
        border: none;
        margin-top: 10px;
    }
    .sk_right h6 {
        border-right: 1px solid #152B4D;
        padding-right: 15px;
        margin-right: 17px;
    }
    .sk_right>.sk_row {
        border-bottom: 1px solid #152B4D;
        margin: 0;
    }
    .sk_right>.sk_row img {
        height: 40px;
        padding-bottom: 10px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}