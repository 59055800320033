.Home {
    color: white;
    width: 99%;
    padding-bottom: 10px;
    padding-top: 30px;
    animation: fadeIn 3200ms;
}

.Home>p {
    font-size: 18px;
    font-weight: 300;
    margin: 6px 0;
    font-family: 'Architects Daughter', cursive;
    color: rgba(156, 155, 155, 0.541)
}

.Home>h1 {
    font-size: 70px;
    font-weight: 900;
}

.Home>h1>span {
    color: var(--color2);
}

button {
    background: none;
    border: 2px solid var(--color2);
    color: rgb(202, 202, 202);
    padding: 10px;
    width: 174px;
    height: 55px;
    margin-top: 20px;
    font-size: 12px;
    font-family: 'Fredoka One', cursive;
    font-weight: 100;
    letter-spacing: 2px;
    outline: none;
}

#arrow {
    margin-left: 5px;
    font-size: 14px;
    display: none;
}

button:hover {
    border: none;
    box-shadow: 0px -1px 5px 1px var(--color2);
    font-size: 13px;
    color: var(--color2);
    -webkit-transition: all 250ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
}

button:hover #arrow {
    border: none;
    display: inline;
}

button:hover #icon {
    animation: wobble 2200ms infinite both;
}

@keyframes wobble {
    from {
        transform: translate3d(0, 0, 0);
    }
    15% {
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }
    30% {
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }
    45% {
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }
    60% {
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }
    75% {
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}