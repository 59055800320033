.projects {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    animation: fadeIn 3200ms;
}

.projects>button {
    margin-left: 80%;
}

button:hover #ic_right {
    display: none;
}

.projects>button>i {
    font-size: 12px;
    font-weight: bold;
    margin: 0 3px;
}

.projects>h1 {
    font-size: 25px;
    text-align: center;
    color: rgba(255, 255, 255, 0.726);
    margin-bottom: 30px;
    margin-top: 80px;
    margin-left: -300px;
    letter-spacing: 4px;
}

.project {
    display: flex;
    width: 900px;
    justify-content: center;
    align-items: center;
    min-height: 280px;
}

.prj_img {
    flex: .5;
    justify-content: center;
    align-items: center;
}

.prj_img>img {
    width: 250px;
    margin-top: 10%;
    padding-right: 50px;
    border-right: 1px solid #1C3458;
}

.prj_description {
    padding: 20px;
    padding-left: 80px;
    width: 56%;
    min-height: 240px;
}

.prj_description>h1 {
    font-size: 50px;
    color: var(--color2);
}

.prj_description>a {
    font-size: 13px;
    color: rgba(156, 155, 155, 0.541);
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
}

.prj_description>a>i {
    margin: 3px;
}

.prj_description>p {
    margin-top: 10px;
    margin-bottom: 25px;
    font-size: 13px;
    color: white;
    font-family: 'Roboto', sans-serif;
    letter-spacing: .8px;
}

.prj_used {
    display: flex;
    text-align: center;
}

.prj_used>img {
    margin-right: 10px;
    height: 30px;
    padding: 0;
}

.steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    margin-right: 300px;
}

.st_icons img {
    margin-right: 3px;
}

.prj_item {
    margin-right: 8px;
}

.prj_item p {
    color: rgba(156, 155, 155, 0.541);
    font-family: 'Architects Daughter', cursive;
    font-size: 14px;
    opacity: 0%;
    text-align: center;
}

.prj_action {
    display: flex;
}

.prj_action button {
    width: 110px;
    height: 40px;
    margin-left: 10px;
    border: none;
    box-shadow: 0px -1px 5px 1px #1e2838;
    -webkit-transition: all 250ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
}

.prj_action button:hover {
    box-shadow: 0px -1px 5px 1px var(--color2);
}

.prj_action button:hover i {
    animation: wobble 2200ms infinite both;
}

.prj_action i {
    margin: 0 5px;
    font-size: 13px;
}

.prj_item:hover p {
    opacity: 100%;
}

.steps>img {
    margin: 10px;
}

.steps>.action>i {
    margin-right: 5px;
    font-size: 24px;
    color: #05a194;
}

.steps>.action>i:hover {
    color: var(--color2);
}

#disable {
    pointer-events: none;
    cursor: default;
    opacity: 30%;
}

@media only screen and (max-width: 1140px) {
    .project {
        flex-direction: column;
        width: 95%;
        justify-content: center;
        align-items: center;
        min-height: 280px;
    }
    .projects>h1 {
        margin-left: 0;
    }
    .projects {
        align-items: center;
    }
    .prj_img img {
        border-right: none;
        width: 280px;
        padding-right: 0;
    }
    .prj_description {
        width: 80%;
        padding-left: 2px;
    }
    .prj_description h1 {
        text-align: center;
    }
    .prj_used {
        justify-content: center;
        flex-wrap: wrap;
    }
    .steps {
        margin-right: 0;
    }
    .projects>button {
        margin-left: 0%;
    }
}